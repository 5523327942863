<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import AppMenuItem from './AppMenuItem.vue';
import { authStore } from '@/store/auth.module';
const auth = authStore();
const { getUser } = storeToRefs(auth);
const user = ref(getUser);

const model = ref([
	{
		label: 'Lift',
		isValid: true,
		icon: 'ph-thin ph-cube',
		items: [
			{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: { name: 'dashboard' }, isValid: true },
			{ label: 'List', icon: 'pi pi-fw pi-box', to: { name: 'lifts' }, isValid: true },
			{ label: 'Trip Log', icon: 'pi pi-fw pi-book', to: { name: 'triplog', params: { id: 0 } }, isValid: true },
			{ label: 'Stats', icon: 'pi pi-fw pi-chart-line', to: { name: 'stats', params: { id: 0 } }, isValid: true },
			{
				label: 'Settings',
				icon: 'pi pi-fw pi-cog',
				isValid: user.value.role === 'superAdmin',
				items: [
					{ label: 'Twin', icon: 'pi pi-fw pi-clone', to: { name: 'twin', params: { id: 0 } }, isValid: user.value.role === 'superAdmin' },
					{ label: 'Idle', icon: 'pi pi-fw pi-stopwatch', to: { name: 'idle', params: { id: 0 } }, isValid: user.value.role === 'superAdmin' },
					{ label: 'History', icon: 'pi pi-fw pi-history', to: { name: 'history', params: { id: 0 } }, isValid: user.value.role === 'superAdmin' },
					{ label: 'Device', icon: 'pi pi-fw pi-microchip', to: { name: 'liftdevices', params: { id: 0 } }, isValid: user.value.role === 'superAdmin' },
					{ label: 'Message', icon: 'pi pi-fw pi-whatsapp', to: { name: 'liftmessage', params: { id: 0 } }, isValid: user.value.role === 'superAdmin' },
					{ label: 'Door', icon: 'pi pi-fw pi-calculator', to: { name: 'doortraining', params: { id: 0 } }, isValid: user.value.role === 'superAdmin' }
				]
			},
			{
				label: 'Device',
				isValid: user.value.role === 'superAdmin',
				items: [
					{ label: 'List', icon: 'pi pi-fw pi-server', to: { name: 'devices' }, isValid: user.value.role === 'superAdmin' },
					{ label: 'Swap', icon: 'pi pi-fw pi-arrow-right-arrow-left', to: { name: 'swap' }, isValid: user.value.role === 'superAdmin' },
					{ label: 'Update', icon: 'pi pi-fw pi-cloud-upload', to: { name: 'devicesupdate' }, isValid: user.value.role === 'superAdmin' }
				]
			},
			{
				label: 'Alerts',
				isValid: true,
				items: [
					{ label: 'List', icon: 'pi pi-fw pi-bell', to: { name: 'alerts' }, isValid: true },
					{ label: 'Rules', icon: 'pi pi-fw pi-sliders-v', to: { name: 'rules' }, isValid: user.value.role === 'superAdmin' },
					{ label: 'Codes', icon: 'pi pi-fw pi-list', to: { name: 'faultcodes' }, isValid: user.value.role === 'superAdmin' },
					{ label: 'Controls', icon: 'pi pi-fw pi-exclamation-triangle', to: { name: 'alertcontrols' }, isValid: user.value.role === 'superAdmin' }
				]
			},
			{
				label: 'Report',
				isValid: user.value.scopes.includes('lift:report:read'),
				items: [{ label: 'Lift', icon: 'pi pi-fw pi-file-pdf', to: { name: 'LiftReport' }, isValid: user.value.scopes.includes('lift:report:read') }]
			}
		]
	},
	{
		label: 'Escalator',
		isValid: true,
		icon: 'ph-thin ph-cube',
		items: [
			{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: { name: 'escalatorsdashboard' }, isValid: true },
			{ label: 'List', icon: 'pi pi-fw pi-list', to: { name: 'escalators' }, isValid: true }
		]
	},
	{
		label: 'Settings',
		isValid: user.value.role === 'superAdmin',
		items: [
			{ label: 'Users', icon: 'pi pi-fw pi-address-book', to: { name: 'users' }, isValid: user.value.role === 'superAdmin' },
			{ label: 'Customers', icon: 'pi pi-fw pi-crown', to: { name: 'customers' }, isValid: user.value.role === 'superAdmin' }
		]
	},
	{ label: 'Workflow', icon: 'pi pi-fw pi-briefcase', url: 'https://workflow.elevate-tech.sg/', isValid: true, items: [{ label: 'Workflow', icon: 'pi pi-fw pi-briefcase', url: 'https://workflow.elevate-tech.sg/', isValid: true }] }
]);
</script>

<template>
	<ul class="layout-menu">
		<template v-for="(item, i) in model" :key="item">
			<app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
			<li v-if="item.separator" class="menu-separator"></li>
		</template>
	</ul>
</template>

<style lang="scss" scoped></style>
